import React, { FC, StrictMode, useEffect, useRef, useState } from 'react'
import { createRoot } from 'react-dom/client'
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom'

import './assets/styles/global.css'
import config from './config'
import { loadAdobeScript } from './shared/utils/loadAdobeScript'
loadAdobeScript()
const SignOutPage = React.lazy(() => import('./pages/login/sign-out'))
const Layout = React.lazy(() => import ('./layouts'))
const StoreProvider = React.lazy(() => import('./redux/provider'))
const Error404Page = React.lazy(() => import('./pages/error-404/error-404.page'))
const LoginCallbackPage = React.lazy(() => import('./pages/login-callback/login-callback'))
const Dashboard = React.lazy(() => import('./pages/dashboard/dashboard.page'))
const PasswordExpiredPage = React.lazy(() => import('./pages/password-expired/password-expired.page'))
const SecurityQuestionsPage = React.lazy(() => import('./pages/security-questions/security-questions.page'))
const ProfilePage = React.lazy(() => import('./pages/profile/profile.page'))
const CardShipmentTrackerPage = React.lazy(() => import('./pages/card-shipment-tracker/card-shipment-tracker.page'))
const ActivateCardPage = React.lazy(() => import('./pages/activate-card/activate-card.page'))
const ChangePinPage = React.lazy(() => import('./pages/change-pin/change-pin.page'))
const LockCardPage = React.lazy(() => import('./pages/lock-card/lock-card.page'))
const AccountOverview = React.lazy(() => import('./pages/account-overview/account-overview.page'))
const router = createBrowserRouter([
  {
    path: '/login/callback',
    element: <React.Suspense><LoginCallbackPage /></React.Suspense>,
  },
  {
    path: '/login/sign-out',
    element: <React.Suspense><SignOutPage /></React.Suspense>,
  },
  {
    path: '/404',
    element: <React.Suspense><Error404Page /></React.Suspense>,
  },
  {
    path: '/',
    element: (
      <React.Suspense fallback={<Layout />}>
        <Layout isFooterVisible={true}><Dashboard /></Layout>
      </React.Suspense>
    ),
  },
  {
    path: '/password-expired',
    element: (
      <React.Suspense fallback={<Layout />}>
        <Layout><PasswordExpiredPage /></Layout>
      </React.Suspense>
    ),
  },
  {
    path: '/profile/edit/security-questions',
    element: (
      <React.Suspense fallback={<Layout />}>
        <Layout><SecurityQuestionsPage /></Layout>
      </React.Suspense>
    ),
  },
  {
    path: '/profile',
    element: (
      <React.Suspense fallback={<Layout />}>
        <Layout><ProfilePage /></Layout>
      </React.Suspense>
    ),
  },
  {
    path: '/account/:accountId/shipment',
    element: (
      <React.Suspense fallback={<Layout />}>
        <Layout><CardShipmentTrackerPage /></Layout>
      </React.Suspense>
    ),
  },
  {
    path: '/account/:accountId/activate',
    element: (
      <React.Suspense fallback={<Layout />}>
        <Layout><ActivateCardPage /></Layout>
      </React.Suspense>
    ),
  },
  {
    path: '/account/:accountId/pin',
    element: (
      <React.Suspense fallback={<Layout />}>
        <Layout><ChangePinPage /></Layout>
      </React.Suspense>
    ),
  },
  {
    path: '/account/:accountId/lock',
    element: (
      <React.Suspense fallback={<Layout />}>
        <Layout><LockCardPage /></Layout>
      </React.Suspense>
    ),
  },
  {
    path: '/account/:accountId',
    element: (
      <React.Suspense fallback={<Layout />}>
        <Layout><AccountOverview /></Layout>
      </React.Suspense>
    ),
  },
])

interface Config {
  AUTH_CLIENT_ID: string
  AUTH_ISSUER: string
  BFF_URL: string
  LOGOUT_URI: string
  REDIRECT_URI: string
  ADOBE_URL: string
}

/**
 * Updates the `./config` file with the config from the server
 * @param newConfig - {@link Config}
 */
const updateConfig = (newConfig: Config) => {
  config.auth.clientId = newConfig.AUTH_CLIENT_ID
  config.auth.issuer = newConfig.AUTH_ISSUER
  config.auth.postLogoutRedirectUri = newConfig.LOGOUT_URI
  config.auth.redirectUri = newConfig.REDIRECT_URI
  config.adobe.url = 'https://assets.adobedtm.com/b598760f96d3/7c72b447e495/launch-ba89eba38d4d-staging.min.js'
}
/**
 * Loads the `/api/config` file and displays the app when the config is loaded.
 * @param props - The children
 */
const ConfigLoader: FC<{ children: JSX.Element }> = (props) => {
  const [isLoaded, setIsLoaded] = useState(false)
  const isMounted = useRef(false)

  useEffect(() => {
    if (!isLoaded && !isMounted.current) {
      fetch('/api/config')
        .then(res => res.json())
        .then((res) => {
          updateConfig(res)
          setIsLoaded(true)
        })
    }
    isMounted.current = true
    return () => {
      isMounted.current = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (isLoaded) {
    return props.children
  }

  return null
}

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ConfigLoader>
      <React.Suspense>
        <StoreProvider>
          <RouterProvider router={router} />
        </StoreProvider>
      </React.Suspense>
    </ConfigLoader>
  </StrictMode>,
)
